import React from "react"
import { graphql } from "gatsby"
import paragraphs from "lines-to-paragraphs"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeaderStyles from "../scss/layouts/blog_headers.module.scss"
import BlogStyles from "../scss/layouts/blog_content.module.scss"
import Arrow from "../images/svg/arrow-right.inline.svg"
import Img from "gatsby-image"


export default ({ data }) => {
  const blog = data.markdownRemark;
  const closingStatement = paragraphs(blog.frontmatter.closingStatement);
  let closingStatementBlock = '';
  if (blog.frontmatter.closingStatement.length > 0) {
    closingStatementBlock = <div dangerouslySetInnerHTML={{ __html: closingStatement  }} className={BlogStyles.blog_content__footer} />
  }

  return (
    <Layout>
      <SEO
        title={blog.frontmatter.title}
        description={blog.frontmatter.excerpt }
        pathname={blog.fields.slug}
        article
      />

        <section className={HeaderStyles.blog_header}>
            <div className={HeaderStyles.blog_header__inner}>
                <div className={HeaderStyles.blog_header__back_link}>
                  <a href="/news"><Arrow />Back to all news</a>
                </div>
                <div className={HeaderStyles.blog_header__info}>
                  <span>{blog.frontmatter.publish_date}</span>
                  <h1>{blog.frontmatter.title}</h1>
                  <p>{blog.frontmatter.excerpt}</p>
                </div>
            </div>
        </section>
        <div className={BlogStyles.blog_content}>
          <div className={BlogStyles.blog_content__main}>
            <div dangerouslySetInnerHTML={{ __html: blog.html }} />
          </div>
        </div>
        { closingStatementBlock }
        <div className={BlogStyles.blog_content__bio}>
            <Img className={BlogStyles.blog_content__avatar}
                  fluid={ blog.frontmatter.avatar.childImageSharp.fluid }
                  alt={"By "+blog.frontmatter.author}
            />
            <span itemProp="author">{"By "+blog.frontmatter.author}</span>
        </div>
        <div className={BlogStyles.blog_content__back}>
           <a href="/news"><Arrow />Back to all News</a>
        </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        publish_date(formatString: "MMMM Do, YYYY")
        author
        avatar {
          childImageSharp {
            fluid(maxWidth: 80, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        excerpt
        longExcerpt
        closingStatement
      }
      html
      fields {
        slug
      }
    }
  }
`
